import React from "react"
import Layout from "../components/Layout"
import { H1 } from "../components/UI"

const ThankYouPage = ({ location }) => {
  return (
    <Layout>
      <H1>Thanks {location.state ? location.state.firstName : null}!</H1>
    </Layout>
  )
}

export default ThankYouPage
